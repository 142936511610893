import { ForwardedRef, forwardRef, ForwardRefExoticComponent, ReactElement, RefAttributes } from 'react'
import { fileLoaderHeader, modalItemTemplate } from 'src/pages/student/HomeworkPage/HomeworkPage'
import { FileUpload as PrimeFileUpload, FileUploadProps } from 'primereact/fileupload'
import './FileUpload.scss'

export type FileUploadType = PrimeFileUpload;

export const FileUpload: ForwardRefExoticComponent<FileUploadProps & RefAttributes<FileUploadType>> = forwardRef<
    FileUploadType,
    FileUploadProps
>((
        props: FileUploadProps,
        ref: ForwardedRef<FileUploadType>,
    ): ReactElement => {
        return (
            <PrimeFileUpload
                ref={ref}
                {...props}
                itemTemplate={modalItemTemplate}
                headerTemplate={fileLoaderHeader}
                multiple={true}
                chooseLabel="Выбрать файлы"
                cancelLabel="Отменить выбор"
                invalidFileSizeMessageSummary="Размер файла(ов) превышает 1 ГБ!"
                invalidFileSizeMessageDetail=""
                id="files"
                mode="advanced"
                name="demo[]"
                maxFileSize={2 ** 30}
            />
        )
    },
)
