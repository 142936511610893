import { MaterialTypeFilterItem } from 'src/pages/Materials/components/MaterialTypeFilter/Item/MaterialTypeFilterItem'
import { createMaterial, deleteMaterial, getMaterials } from 'src/store/admin/materials/materials.actions'
import { Source, SourceColor } from 'src/store/student/material/material.types'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { IDropdownItem } from '../student/ProgressPage/ProgressPage.types'
import { Material } from 'src/store/admin/materials/materials.types'
import { FC, MouseEvent, ReactElement, RefObject, useEffect, useRef, useState } from 'react'
import { CanAttachPackageSelector } from 'src/store/selectors'
import { _notify, NotificationTypes, notifyAboutException, notifyAboutSuccess } from 'src/utils/notify'
import useDateFormatter from 'src/hooks/useDateFormatter'
import { ConfirmPopup } from 'primereact/confirmpopup'
import useAppSelector from 'src/hooks/useAppSelector'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { confirmTooltip } from 'src/utils/confirm'
import { Modal } from 'src/components/Modal/Modal'
import { InputText } from 'primereact/inputtext'
import { downloadFile } from 'src/downloadFile'
import { Dropdown } from 'primereact/dropdown'
import { Helmet } from 'react-helmet-async'
import Loader from 'src/components/Loader'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { size } from 'lodash'
import {
    MaterialEditingPopupContent,
} from 'src/pages/Materials/components/MaterialEditingPopup/Content/MaterialEditingPopupContent'
import { SingleParamFunction } from 'src/@types'

export interface Form {
    source: Source | null;
    name: number | null;
    theme: string;
    link: string | null;
    files?: Array<File>;
    homeWorkFile?: Array<File>;
    practice?: Array<File>;
    presentation?: Array<File>;
}

interface MaterialsPageProps {
    allowEdit?: boolean;
}

export const MaterialsPage: FC<MaterialsPageProps> = (
    props: MaterialsPageProps,
): ReactElement => {
    const { allowEdit } = props
    
    const dispatch = useAppDispatch()
    
    const canAttachPackage: boolean = useAppSelector(CanAttachPackageSelector)
    const toast: RefObject<Toast> = useRef<Toast>(null)
    
    const [modal, setModal] = useState<{
        id: number | null;
        visible: boolean;
    }>({ id: null, visible: false })
    
    const [loadingMaterial, setLoadingMaterial] = useState<number | null>(null)
    
    const getData: VoidFunction = (): void => {
        dispatch(getMaterials())
            .unwrap()
            .catch((reason: any): void => {
                notifyAboutException({
                    toast: toast,
                    message: reason,
                })
            })
    }
    
    const onHide: VoidFunction = (): void => {
        setModal({ id: null, visible: false })
    }
    
    const onMaterialCreate: SingleParamFunction<Form> = (form: Form): void => {
        dispatch(
            createMaterial({
                ...form,
                homeWorkFile: size(form.homeWorkFile)
                              ? form.homeWorkFile
                              : undefined,
            }),
        )
            .unwrap()
            .then((): void => {
                notifyAboutSuccess({
                    toast: toast,
                    message: 'Материал был успешно создан',
                })
                onHide()
            })
            .then((): void => {
                getData()
            })
            .catch((reason: any): void => {
                notifyAboutException({
                    toast: toast,
                    message: reason,
                })
            })
    }
    
    useEffect((): void => {
        getData()
    }, [])
    
    const { data } = useAppSelector(state => state.adminMaterialReducer)
    
    const { pending } = useAppSelector(
        state => state.adminMaterialReducer.getMaterials,
    )
    
    const [materials, setMaterials] = useState<Material[]>([])
    const [filter, setFilter] = useState<{
        source?: Source;
        subject?: IDropdownItem<string>;
        theme?: IDropdownItem<string>;
        search?: string;
    }>({})
    
    const _ = useDateFormatter()
    
    useEffect((): void => {
        setMaterials(data.materials)
    }, [data.materials])
    
    useEffect((): void => {
        if (
            !filter.search &&
            !filter.source &&
            !filter.subject &&
            !filter.theme
        ) {
            setMaterials(data.materials)
            return
        }
        
        let qs = data.materials
        
        if (filter.source) {
            qs = qs.filter(m => m.source === filter.source)
        }
        
        if (filter.subject && filter.subject.code !== 'all') {
            qs = qs.filter(m => m.theme === filter.subject?.name)
        }
        
        if (filter.theme && filter.theme.code !== 'all') {
            qs = qs.filter(m => m.name === filter.theme?.code)
        }
        
        if (filter.search && filter.search.trim().length) {
            qs = qs.filter(m =>
                m.name
                    .toLocaleLowerCase()
                    .includes(filter.search!.toLocaleLowerCase().trim()),
            )
        }
        
        setMaterials(qs)
    }, [filter, data])
    
    function onFilterChange(source: Source) {
        setFilter(prev => {
            if (prev.source === source) {
                return {
                    ...prev,
                    source: undefined,
                }
            }
            
            return { ...prev, source }
        })
    }
    
    function onDelete(
        event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
        id: number,
    ): void {
        if (event) {
            confirmTooltip(event.currentTarget, {
                message: 'Вы уверены что хотите удалить?',
                accept: () => {
                    setLoadingMaterial(id)
                    dispatch(
                        deleteMaterial({
                            id,
                        }),
                    )
                        .unwrap()
                        .then(() =>
                            _notify(
                                toast,
                                {
                                    type: NotificationTypes.success,
                                    content: 'Вы успешно удалили материал!',
                                },
                                true,
                            ),
                        )
                        .then(getData)
                        .catch(e => {
                            _notify(toast, {
                                type: NotificationTypes.error,
                                content: e,
                            })
                        })
                        .finally(() => {
                            setLoadingMaterial(null)
                        })
                },
            })
        }
    }
    
    function onUpdate(material: Material): void {
        setModal({ id: material.id, visible: true })
    }
    
    const allow = allowEdit && canAttachPackage
    return (
        <StudentLayout pageName="materials-page">
            <Helmet title={'Методические материалы'} />
            <ConfirmPopup />
            <Toast ref={toast} />
            {allow ? (
                <Modal
                    header={`${modal.id ? 'Редактирование' : 'Создание'} материала`}
                    visible={modal.visible}
                    onHide={onHide}
                >
                    <MaterialEditingPopupContent
                        id={modal.id}
                        onSubmit={onMaterialCreate}
                        onValid={(): void => {
                            getData()
                            onHide()
                        }}
                    />
                </Modal>
            ) : null}
            <div className="card mt-24">
                <div className="header flex align-items-center justify-content-between materials-bg">
                    <div className="title big">Методические материалы</div>
                </div>
                {pending ? (
                    <Loader />
                ) : (
                    <div className="content">
                        <div className="text fz-20 bold">
                            Методические материалы
                        </div>
                        {allow ? (
                            <Button
                                className={'mt-24'}
                                onClick={() =>
                                    setModal({ id: null, visible: true })
                                }
                            >
                                Загрузить материалы
                            </Button>
                        ) : null}
                        <div className="flex mt-24 p-12 pl-4 source-filter">
                            <div className="pair">
                                <MaterialTypeFilterItem
                                    id={0}
                                    label="Уроки"
                                    source={filter.source}
                                    onClick={(): void => onFilterChange('Уроки')}
                                />
                                <MaterialTypeFilterItem
                                    id={1}
                                    label="Библиотека"
                                    source={filter.source}
                                    className={'ml-24'}
                                    onClick={(): void => onFilterChange('Библиотека')}
                                />
                            </div>
                            <div className="pair">
                                <MaterialTypeFilterItem
                                    id={2}
                                    label="Видео"
                                    source={filter.source}
                                    className={'ml-24'}
                                    onClick={(): void => onFilterChange('Видео')}
                                />
                                <MaterialTypeFilterItem
                                    id={3}
                                    label="Статьи"
                                    source={filter.source}
                                    className={'ml-24'}
                                    onClick={(): void => onFilterChange('Статьи')}
                                />
                            </div>
                        </div>
                        <div className="flex filter-container filter-container mt-12">
                            <Dropdown
                                value={filter.subject}
                                className="w-300"
                                options={[
                                    {
                                        name: '------',
                                        code: 'all',
                                    },
                                    ...data.subjects.map(s => ({
                                        name: s.name,
                                        code: s.name,
                                    })),
                                ]}
                                onChange={event =>
                                    setFilter(prev => ({
                                        ...prev,
                                        subject: event.value,
                                        theme: data.subjects
                                                   .find(
                                                       s => s.name === event.value.name,
                                                   )
                                                   ?.themes.includes(
                                                prev.theme
                                                ? prev.theme.name
                                                : '',
                                            )
                                               ? prev.theme
                                               : undefined,
                                    }))
                                }
                                optionLabel="name"
                                placeholder="Выберите предмет"
                            />
                            <Dropdown
                                value={filter.theme}
                                className="mt ml-24 w-300"
                                options={[
                                    {
                                        name: '------',
                                        code: 'all',
                                    },
                                    ...(data.subjects
                                        .find(
                                            s => s.name === filter.subject?.name,
                                        )
                                        ?.themes.map(t => ({
                                            name: t,
                                            code: t,
                                        })) || []),
                                ]}
                                onChange={event =>
                                    setFilter(prev => ({
                                        ...prev,
                                        theme: event.value,
                                    }))
                                }
                                optionLabel="name"
                                placeholder="Выберите тему"
                            />
                            <span className="p-input-icon-right ml-24 mt">
                                <i className="pi pi-search" />
                                <InputText
                                    value={filter.search}
                                    onChange={event =>
                                        setFilter(prev => ({
                                            ...prev,
                                            search: event.target.value,
                                        }))
                                    }
                                    placeholder="Поиск"
                                />
                            </span>
                        </div>
                        <div className="grid mt-48">
                            {materials.length ? (
                                materials.map(material => (
                                    <div
                                        className="lg:col-3 md:col-6 col-12"
                                        key={material.id}
                                    >
                                        <div
                                            className="area material bg flex flex-column justify-content-between h-full">
                                            <div
                                                className={
                                                    'body flex flex-column justify-content-between'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'flex flex-column'
                                                    }
                                                >
                                                    <div className="flex justify-content-between preview-container">
                                                        <div
                                                            className="source-label"
                                                            style={{
                                                                color: SourceColor[
                                                                    material
                                                                        .source
                                                                    ],
                                                                backgroundColor:
                                                                    SourceColor[
                                                                        material
                                                                            .source
                                                                        ] + '20',
                                                            }}
                                                        >
                                                            {material.source[0]}
                                                        </div>
                                                        <small className="date block ml-72">
                                                            {_(
                                                                material.createdDate,
                                                            )}
                                                        </small>
                                                    </div>
                                                    <div
                                                        className="text mt-24"
                                                        style={{ fontSize: 18 }}
                                                    >
                                                        {material.theme}
                                                    </div>
                                                    <div className="text bold mt-24 mb-64">
                                                        {material.name}
                                                    </div>
                                                </div>
                                                <div
                                                    className={'flex flex-wrap'}
                                                    style={{ gap: 12 }}
                                                >
                                                    <Button
                                                        style={{ flex: 1 }}
                                                        className="p-button-sm"
                                                        onClick={_ =>
                                                            onUpdate(material)
                                                        }
                                                        loading={
                                                            material.id ===
                                                            loadingMaterial
                                                        }
                                                        icon={'pi pi-pencil'}
                                                    />
                                                    {allow ? (
                                                        <Button
                                                            style={{ flex: 1 }}
                                                            className="p-button-sm"
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            onClick={event =>
                                                                onDelete(
                                                                    event,
                                                                    material.id,
                                                                )
                                                            }
                                                            icon={'pi pi-trash'}
                                                        />
                                                    ) : null}
                                                    {material.pathFileMaterial ? (
                                                        <Button
                                                            className="p-button-sm flex-grow-1"
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            icon={
                                                                'pi pi-download'
                                                            }
                                                            onClick={() => {
                                                                linkOrCallback(
                                                                    material.pathFileMaterial,
                                                                    () => {
                                                                        if (
                                                                            !material.pathFileMaterial
                                                                        )
                                                                            return
                                                                        
                                                                        setLoadingMaterial(
                                                                            material.id,
                                                                        )
                                                                        downloadFile(
                                                                            material.pathFileMaterial,
                                                                        )
                                                                            .catch(
                                                                                e => {
                                                                                    if (
                                                                                        e instanceof
                                                                                        Error
                                                                                    ) {
                                                                                        _notify(
                                                                                            window.Toast,
                                                                                            {
                                                                                                type: NotificationTypes.error,
                                                                                                content:
                                                                                                e.message,
                                                                                            },
                                                                                            true,
                                                                                        )
                                                                                    }
                                                                                },
                                                                            )
                                                                            .finally(
                                                                                () => {
                                                                                    setLoadingMaterial(
                                                                                        null,
                                                                                    )
                                                                                },
                                                                            )
                                                                    },
                                                                )
                                                            }}
                                                            label={'Материал'}
                                                        />
                                                    ) : null}
                                                    {material.pathHomeworkFile ? (
                                                        <Button
                                                            className="p-button-sm flex-grow-1"
                                                            icon={
                                                                'pi pi-download'
                                                            }
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            onClick={() => {
                                                                linkOrCallback(
                                                                    material.pathHomeworkFile,
                                                                    () => {
                                                                        if (
                                                                            !material.pathHomeworkFile
                                                                        )
                                                                            return
                                                                        
                                                                        setLoadingMaterial(
                                                                            material.id,
                                                                        )
                                                                        downloadFile(
                                                                            material.pathHomeworkFile,
                                                                        )
                                                                            .catch(
                                                                                e => {
                                                                                    if (
                                                                                        e instanceof
                                                                                        Error
                                                                                    ) {
                                                                                        _notify(
                                                                                            window.Toast,
                                                                                            {
                                                                                                type: NotificationTypes.error,
                                                                                                content:
                                                                                                e.message,
                                                                                            },
                                                                                            true,
                                                                                        )
                                                                                    }
                                                                                },
                                                                            )
                                                                            .finally(
                                                                                () => {
                                                                                    setLoadingMaterial(
                                                                                        null,
                                                                                    )
                                                                                },
                                                                            )
                                                                    },
                                                                )
                                                            }}
                                                            label={
                                                                'Домашнее задание'
                                                            }
                                                        />
                                                    ) : null}
                                                    {material.pathPresentationFile ? (
                                                        <Button
                                                            className="p-button-sm flex-grow-1"
                                                            icon={
                                                                'pi pi-download'
                                                            }
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            onClick={() => {
                                                                linkOrCallback(
                                                                    material.pathPresentationFile,
                                                                    () => {
                                                                        if (
                                                                            !material.pathPresentationFile
                                                                        )
                                                                            return
                                                                        
                                                                        setLoadingMaterial(
                                                                            material.id,
                                                                        )
                                                                        downloadFile(
                                                                            material.pathPresentationFile,
                                                                        )
                                                                            .catch(
                                                                                e => {
                                                                                    if (
                                                                                        e instanceof
                                                                                        Error
                                                                                    ) {
                                                                                        _notify(
                                                                                            window.Toast,
                                                                                            {
                                                                                                type: NotificationTypes.error,
                                                                                                content:
                                                                                                e.message,
                                                                                            },
                                                                                            true,
                                                                                        )
                                                                                    }
                                                                                },
                                                                            )
                                                                            .finally(
                                                                                () => {
                                                                                    setLoadingMaterial(
                                                                                        null,
                                                                                    )
                                                                                },
                                                                            )
                                                                    },
                                                                )
                                                            }}
                                                            label={
                                                                'Презентация'
                                                            }
                                                        />
                                                    ) : null}
                                                    {material.pathPracticeFile ? (
                                                        <Button
                                                            className="p-button-sm flex-grow-1"
                                                            icon={
                                                                'pi pi-download'
                                                            }
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !material.pathPracticeFile
                                                                )
                                                                    return
                                                                
                                                                linkOrCallback(
                                                                    material.pathPracticeFile,
                                                                    () => {
                                                                        if (
                                                                            !material.pathPracticeFile
                                                                        )
                                                                            return
                                                                        
                                                                        setLoadingMaterial(
                                                                            material.id,
                                                                        )
                                                                        downloadFile(
                                                                            material.pathPracticeFile,
                                                                        )
                                                                            .catch(
                                                                                e => {
                                                                                    if (
                                                                                        e instanceof
                                                                                        Error
                                                                                    ) {
                                                                                        _notify(
                                                                                            window.Toast,
                                                                                            {
                                                                                                type: NotificationTypes.error,
                                                                                                content:
                                                                                                e.message,
                                                                                            },
                                                                                            true,
                                                                                        )
                                                                                    }
                                                                                },
                                                                            )
                                                                            .finally(
                                                                                () => {
                                                                                    setLoadingMaterial(
                                                                                        null,
                                                                                    )
                                                                                },
                                                                            )
                                                                    },
                                                                )
                                                            }}
                                                            label={
                                                                'Практическая'
                                                            }
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                 <div className="fz-20 px-2">
                                     Здесь пока ничего нет
                                 </div>
                             )}
                        </div>
                    </div>
                )}
            </div>
        </StudentLayout>
    )
}

function linkOrCallback(link: string | null, callback: () => void): void {
    if (link) {
        if (link.startsWith('http')) {
            window.open(link, '_blank')?.focus()
        } else {
            callback()
        }
    }
}
