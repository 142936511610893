import { createAsyncThunk } from '@reduxjs/toolkit';
import { IMaterialType } from './materials.types';
import { Form } from 'src/pages/Materials'
import { size } from 'lodash';
import http from 'src/http'

export const getMaterials = createAsyncThunk<
    IMaterialType,
    void | { subjectId?: number }
>('teacher/materials/getMaterialData', async (params, thunkAPI) => {
    try {
        const { data } = await http.get('/materials/getMaterialDataTeacher', {
            params,
        });
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(
            'Не удалось получить данные о материалах!'
        );
    }
});

export const deleteMaterial = createAsyncThunk<unknown, { id: number }>(
    'teacher/materials/deleteMaterial',
    async (params, thunkAPI) => {
        try {
            const { data } = await http.delete('/materials/deleteMaterial', {
                params,
            });
            if (data?.message) return thunkAPI.rejectWithValue(data.message);
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось удалить материал!');
        }
    }
);

function appendFile(
    formData: FormData,
    file: { key: string; file: Array<File> | undefined },
    link?: { key: string; link: string | null },
) {
    if (!file.file) return;
    if (size(file.file)) {
        for (let i = 0; i < file.file.length; i++) {
            formData.append(file.key, file.file[i]!, file.file[i]?.name);
        }
    } else if (link?.link?.trim().length) {
        formData.append(link.key, link.link);
    }
}

export const createMaterial = createAsyncThunk<unknown, Form>(
    'teacher/materials/createMaterial',
    async (
        { homeWorkFile, practice, presentation, files, link, ...rest },
        thunkAPI
    ) => {
        try {
            const formData = new FormData();
            
            for (const key in rest) {
                formData.append(key, rest[key]);
            }
            
            appendFile(formData, {
                key: 'presentationFile',
                file: presentation,
            });
            
            appendFile(formData, { key: 'practiceFile', file: practice });
            appendFile(formData, { key: 'homeworkFile', file: homeWorkFile });
            appendFile(formData, { key: 'files', file: files }, { key: 'link', link })
            
            await http.post('/materials/createMaterial', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (_reason: any) {
            return thunkAPI.rejectWithValue('Не удалось создать материал!');
        }
    }
);
