import { NewFile, UpdateFile } from 'src/api/endpoints/materials'
import { filter, map } from 'lodash'
import { SingleParamFunction } from 'src/@types'
import { Nullable } from 'primereact/ts-helpers'

const toBase64: SingleParamFunction<File, Promise<NewFile>> = (file: File): Promise<NewFile> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () =>
            resolve({
                content: Array.from(
                    reader.result
                    ? new Uint8Array(reader.result as ArrayBufferLike)
                    : [],
                ),
                name: file.name,
            })
        reader.onerror = error => reject(error)
    })

export const generateFile = (name: string): File => {
    return new File([], name, { lastModified: 0 }) // пустые файлы с lastModified : 0
}

export const formatFiles: SingleParamFunction<Nullable<Array<File>>, Promise<UpdateFile>> = (
    files: Array<File> | null | undefined,
): Promise<UpdateFile> => {
    if (!files)
        return new Promise(resolve => {
            resolve({
                news: [],
                old: [],
            })
        })
    
    const old = map(
        filter(files, file => file.lastModified === 0),
        file => file.name,
    )
    return Promise.all(
        map(
            filter(files, file => file.lastModified !== 0),
            file => toBase64(file),
        ),
    ).then(convertedFiles => {
        return {
            news: convertedFiles,
            old,
        }
    })
}