import { Source, SourceColor } from 'src/store/student/material/material.types'
import React, { FC, ReactElement } from 'react'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'

export type MaterialTypeFilterItemProps = {
    id: number;
    label: Source;
    onClick: () => void;
    source?: Source | null;
    className?: string;
}

export const MaterialTypeFilterItem: FC<MaterialTypeFilterItemProps> = (
    props: MaterialTypeFilterItemProps,
): ReactElement => {
    const {
        id,
        label,
        onClick,
        source,
        className,
    } = props
    
    return (
        <Button
            type={'button'}
            label={label}
            onClick={onClick}
            className={classNames(
                `p-button-rounded id-${id} min-w-fit`,
                className,
            )}
            style={{
                borderColor: SourceColor[label],
                background:
                    source === label
                    ? SourceColor[label]
                    : SourceColor[label] + '20',
                color: source === label ? '#fff' : SourceColor[label],
            }}
        />
    )
}