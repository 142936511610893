import { Form } from 'src/pages/Materials'
import { Formik, useFormik } from 'formik'
import { requireField } from 'src/constants/constants'
import { filter, isEmpty, map } from 'lodash'
import { validateUrl } from 'src/utils/validateUrl'
import { _notify, NotificationTypes } from 'src/utils/notify'
import {
    useGetMaterialByIdQuery,
    useGetProgrammeNamesQuery,
    useUpdateMaterialMutation,
} from 'src/api/endpoints/materials'
import useAppSelector from 'src/hooks/useAppSelector'
import React, { FC, ReactElement, useEffect, useRef } from 'react'
import { FileUpload, FileUploadType } from 'src/components/FileUpload/FileUpload'
import { Dropdown as AppDropDown } from 'src/components/Dropdown'
import { classNames } from 'primereact/utils'
import { InputTextarea } from 'primereact/inputtextarea'
import { Source, SourceColor } from 'src/store/student/material/material.types'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import {
    formatFiles,
    generateFile,
} from 'src/pages/Materials/components/MaterialEditingPopup/Content/MaterialsEditingContent.functions'
import { MaterialTypeFilterItem } from 'src/pages/Materials/components/MaterialTypeFilter/Item/MaterialTypeFilterItem'
import { FileUploadRemoveEvent, FileUploadSelectEvent } from 'primereact/fileupload'

export type MaterialEditingPopupContentProps = {
    id: number | null;
    onSubmit: (values: Form) => void;
    onValid: () => void;
};

export const MaterialEditingPopupContent: FC<MaterialEditingPopupContentProps> = (
    props: MaterialEditingPopupContentProps,
): ReactElement => {
    const { id, onSubmit, onValid } = props
    
    const formik: Formik<Form> = useFormik<Form>({
        initialValues: {
            name: null,
            theme: '',
            source: null,
            link: '',
            files: [],
            homeWorkFile: [],
            practice: [],
            presentation: [],
        },
        validate: ({ name, source, theme, files, link }: Form) => {
            const errors: Partial<Record<keyof Form, any>> = {}
            
            if (!name) errors.name = requireField
            if (!theme) errors.theme = requireField
            if (!source) errors.source = requireField
            
            if (!link?.trim().length && isEmpty(files)) {
                errors.link = 'Введите ссылку или выберите файл'
                errors.files = 'Введите ссылку или выберите файл'
            } else {
                if (link && !validateUrl(link)) {
                    errors.link = 'Ссылка не прошла валидацию'
                }
            }
            return errors
        },
        onSubmit: (values: Form): void => {
            if (!id) return onSubmit(values)
            
            Promise.all([
                    formatFiles(values.files),
                    formatFiles(values.homeWorkFile),
                    formatFiles(values.practice),
                    formatFiles(values.presentation),
                ])
                .then(
                    ([files, homeworkFile, practiceFile, presentationFile]): void => {
                        if (
                            !values
                            || values.name === null
                            || !values.source
                        ) return
                        
                        update({
                            materialId: id,
                            name: values.name,
                            link: values.link,
                            theme: values.theme,
                            source: values.source,
                            files,
                            homeworkFile,
                            practiceFile,
                            presentationFile,
                        })
                            .unwrap()
                            .then((): void => {
                                onValid()
                                _notify(
                                    window.Toast,
                                    {
                                        type: NotificationTypes.success,
                                        content: 'Материал успешно обновлён!',
                                    },
                                    true,
                                )
                            })
                    },
                )
                .catch(console.error)
        },
    })
    
    const { link, theme, files, homeWorkFile, name, presentation, practice } =
        formik.values
    
    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name])
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        )
    }
    
    const { data: themes, isFetching: isThemesFetching } =
        useGetProgrammeNamesQuery()
    const { pending } = useAppSelector(
        state => state.adminMaterialReducer.createMaterial,
    )
    const { data } = useGetMaterialByIdQuery({ id }, { skip: !id })
    const [update, { isLoading }] = useUpdateMaterialMutation()
    
    useEffect((): void => {
        if (
            data &&
            fileRef.current &&
            homeworkRef.current &&
            practiceRef.current &&
            presentationRef.current
        ) {
            const file = map(data.fileMaterials, name => generateFile(name)),
                homework = map(data.homeworkFiles, name => generateFile(name)),
                practice = map(data.practiceFiles, name => generateFile(name)),
                presentation = map(data.presentationFiles, name =>
                    generateFile(name),
                )
            
            formik.setFieldValue('files', file)
            formik.setFieldValue('homework', homework)
            formik.setFieldValue('practice', practice)
            formik.setFieldValue('presentation', presentation)
            formik.setFieldValue('link', data.link)
            formik.setFieldValue('theme', data.theme)
            formik.setFieldValue('name', data.nameId)
            formik.setFieldValue('source', data.source)
            
            fileRef.current.setFiles(file)
            homeworkRef.current.setFiles(homework)
            practiceRef.current.setFiles(practice)
            presentationRef.current.setFiles(presentation)
        }
    }, [data])
    
    const fileRef = useRef<FileUploadType>(null)
    const homeworkRef = useRef<FileUploadType>(null)
    const practiceRef = useRef<FileUploadType>(null)
    const presentationRef = useRef<FileUploadType>(null)
    
    return (
        <div>
            <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="field">
                    <span className="p-float-label">
                        <AppDropDown
                            filter
                            id="name"
                            name="name"
                            options={map(themes, ({ name, id }) => ({
                                label: name,
                                value: id,
                            }))}
                            loading={isThemesFetching}
                            value={name}
                            onChange={formik.handleChange}
                            className={classNames({
                                'p-invalid': isFormFieldValid('name'),
                            })}
                        />
                        <label
                            htmlFor="name"
                            className={classNames({
                                'p-error': isFormFieldValid('name'),
                            })}
                        >
                            Предмет
                        </label>
                    </span>
                    {getFormErrorMessage('name')}
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputTextarea
                            id="theme"
                            name="theme"
                            value={theme}
                            onChange={formik.handleChange}
                            className={classNames({
                                'p-invalid': isFormFieldValid('theme'),
                            })}
                        />
                        <label
                            htmlFor="theme"
                            className={classNames({
                                'p-error': isFormFieldValid('theme'),
                            })}
                        >
                            Тема
                        </label>
                    </span>
                    {getFormErrorMessage('theme')}
                </div>
                <div className={'field'}>
                    <div className={'flex gap-24 flex-wrap'}>
                        {map(
                            Object.keys(SourceColor),
                            (source: Source, index: number): ReactElement => (
                                <MaterialTypeFilterItem
                                    id={index}
                                    key={source}
                                    label={source}
                                    source={formik.values.source}
                                    className={'flex-1'}
                                    onClick={() => {
                                        formik.setFieldValue('source', source)
                                    }}
                                />
                            ),
                        )}
                    </div>
                    {getFormErrorMessage('source')}
                </div>
                <small className="date">Вставьте ссылку</small>
                <div className="field mt-8">
                    <InputText
                        disabled={!!files?.length}
                        id="link"
                        name="link"
                        className={classNames({
                            'p-invalid': isFormFieldValid('link'),
                        })}
                        onChange={formik.handleChange}
                        placeholder="https://example.com/"
                        value={link || undefined}
                    />
                    {getFormErrorMessage('link')}
                </div>
                <small className="date">Или прикрепите файлы</small>
                <div className="field mt-8">
                    <FileUpload
                        ref={fileRef}
                        disabled={!!link?.trim().length}
                        onSelect={(event: FileUploadSelectEvent): void => {
                            formik.setFieldValue('files', event.files)
                        }}
                        onClear={(): void => {
                            formik.setFieldValue('files', null)
                        }}
                        onRemove={(event: FileUploadRemoveEvent): void => {
                            formik.setFieldValue(
                                'files',
                                filter(
                                    files,
                                    (file: File): boolean => file.name !== event.file.name,
                                ),
                            )
                        }}
                    />
                    {getFormErrorMessage('files')}
                </div>
                <small className={'date'}>Домашнее задание</small>
                <div className="field mt-8">
                    <FileUpload
                        ref={homeworkRef}
                        onSelect={event =>
                            formik.setFieldValue('homeWorkFile', event.files)
                        }
                        onClear={() =>
                            formik.setFieldValue('homeWorkFile', null)
                        }
                        onRemove={e =>
                            formik.setFieldValue(
                                'homeWorkFile',
                                filter(
                                    homeWorkFile,
                                    _file => _file.name !== e.file.name,
                                ),
                            )
                        }
                    />
                    {getFormErrorMessage('homeWorkFile')}
                </div>
                <small className={'date'}>Презентация</small>
                <div className="field mt-8">
                    <FileUpload
                        ref={presentationRef}
                        onSelect={event =>
                            formik.setFieldValue('presentation', event.files)
                        }
                        onClear={() =>
                            formik.setFieldValue('presentation', null)
                        }
                        onRemove={e =>
                            formik.setFieldValue(
                                'presentation',
                                filter(
                                    presentation,
                                    _file => _file.name !== e.file.name,
                                ),
                            )
                        }
                    />
                    {getFormErrorMessage('presentation')}
                </div>
                <small className={'date'}>Практическая</small>
                <div className="field mt-8">
                    <FileUpload
                        ref={practiceRef}
                        onSelect={event =>
                            formik.setFieldValue('practice', event.files)
                        }
                        onClear={() => formik.setFieldValue('practice', null)}
                        onRemove={e =>
                            formik.setFieldValue(
                                'practice',
                                filter(
                                    practice,
                                    _file => _file.name !== e.file.name,
                                ),
                            )
                        }
                    />
                    {getFormErrorMessage('practice')}
                </div>
                <Button
                    loading={id ? isLoading : pending}
                    type="submit"
                    label="Сохранить"
                    className={'w-auto'}
                />
            </form>
        </div>
    )
}